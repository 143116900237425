<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <div class="card card-body">
        <div v-if="step == 0">
        </div>
        <div v-if="step == 1">
            <h3>
                {{ title }}
            </h3>
            <div class="alert alert-warning g">
                <span class="text-dark">{{ description }}</span>
            </div>
            <div class="row">
                <div class="col-12 g">
                    <h4>
                        هل تريد الافصاح عن اسمك؟
                    </h4>
                </div>
                <div class="col-6 g">
                    <button class="btn btn-success btn-block" @click="hideme = false; step = 2">
                        <i class="fa fa-check"></i>
                        نعم
                    </button>
                </div>
                <div class="col-6 g">
                    <button class="btn btn-danger btn-block" @click="hideme = true; step = 2;">
                        <i class="fa fa-times"></i>
                        لا
                    </button>
                </div>
            </div>
        </div>
        <div v-if="step == 2">
            <h5>
                معلومات الطالب العامة
            </h5>
            <template v-for="item in arr">
                <div class="col-12 g" :key="item.title" v-if="item.type == '1'">
                    <b-form-group :label="item.title" v-slot="{ ariaDescribedby }">
                        <template v-for="v in item.options">
                            <b-form-radio :key="v" v-model="selected[item.title]" :aria-describedby="ariaDescribedby" :value="v">{{ v }}</b-form-radio>
                        </template>
                    </b-form-group>
                </div>
            </template>
            <div class="col-12 text-center g">
                <button class="btn btn-secondary" @click="step = 1"><i class="fa fa-arrow-right"></i> السابق</button>
                &nbsp;
                <button class="btn btn-primary" @click="step = 3; top()">التالي <i class="fa fa-arrow-left"></i></button>
            </div>
        </div>
        <div v-if="step == 3">
            <h5>
                المشكلات الدراسية
            </h5>
            <template v-for="item in arr">
                <div class="col-12 g" :key="item.title" v-if="item.type != '1'">
                     <b-form-group :label="item.title.replace(' ', '- ')" v-slot="{ ariaDescribedby }">
                        <template v-for="v in options">
                            <b-form-radio :key="v" v-model="selected[item.title]" :aria-describedby="ariaDescribedby" inline :value="v">{{ v }}</b-form-radio>
                        </template>
                    </b-form-group>
                </div>
            </template>
            <div class="col-12 text-center g">
                <button class="btn btn-secondary" @click="step = 2; top()"><i class="fa fa-arrow-right"></i> السابق</button>
                &nbsp;
                <button class="btn btn-success" @click="end()"><i class="fa fa-check"></i> انهاء الاجابة</button>
            </div>
        </div>
    </div>
    <div class="col-12 text-center text-muted">
        الخدمة مقدمة من نظام <a href="https://tahdir.net" target="_blank">التحضير الذكي</a>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormRadio, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BFormRadio,
        BFormGroup
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            step: 0,
            hideme: false,
            id: window.location.href.split("que/")[1],
            arr: [],
            title: "",
            description: "",
            selected: {},
            options: ["نعم", "لا", "أحياناً"]
        }
    },
    created(){
        var g = this;
        $.post(api + '/general/que', {
            id: this.id
        }).then(function(r){
            g.step = 1
            if(r.status != 100){
                alert(r.response, 200)
                g.step = 0
            }else{
                g.arr = r.response.arr;
                g.title = r.response.title;
                g.description = r.response.description;
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200);
            g.step = 0
        })
    },
    methods: {
        end(){
            var g = this;
            if(Object.keys(g.selected).length != g.arr.length){
                return alert("هناك حقول لم يتم الاجابة عنها", 200)
            }
            $.post(api + '/general/que-end', {
                id: this.id,
                arr: JSON.stringify(this.selected),
                hideme: this.hideme
            }).then(function(r){
                g.step = 1
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الارسال بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200);
            })
        },
        top(){
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }
    }
}
</script>

<style>

</style>